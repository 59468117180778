<template>
  <div class="agreement">
		<div class="block"></div>
		<h3>用户知情同意书</h3>
		<div>
			<p>【默沙东（中国）投资有限公司】（下称“默沙东”或“我们”）非常重视用户的隐私和个人信息保护。您在注册、使用我们的【慢咳查询小工具】（以下简称“本平台”）时，我们可能会收集和使用您的个人信息。我们希望通过本知情同意书向您说明我们在您使用我们的产品和服务时如何收集、使用、保存、共享和转让这些个人信息，以及您对这些个人信息享有的权利。</p>
			<p>【本平台为医疗卫生专业人士提供慢咳领域疾病知识及前沿进展】。为如下所述之目的，我们将通过本程序收集、使用和处理您的如下个人信息。</p>
			<p>【本平台仅向医疗卫生专业人士开放。】当为您提供【慢咳领域相关病因分析、评估工具及疾病机制内容】时，为了验证您的医疗卫生专业人士身份，我们将会收集、使用您的【微信用户id信息】。提示您注意，如您不同意我们收集前述信息，可能无法使用本平台服务，但不影响您使用我们提供的其他产品和服务。</p>
			<p>【为了更好地了解用户如何使用本平台以及如何使其更具帮助性】，我们会收集、使用您的【内容访问信息、访问时长、访问次数等相关个人行为信息】。</p>
			<p>我们也可能从第三方处收集您的个人信息，包括我们的业务合作伙伴和服务提供商。我们要求任何可能向我们共享您个人信息的业务合作伙伴和服务提供商征得您的同意。</p>
			<p>我们可能会对您提供的个人信息进行数据分析，包括帮助我们改进服务和为您提供定制化的信息。如果您选择不向我们提供您的个人信息，我们可能无法实现这些目的。</p>
			<p>我们只会在实现上述目的所需的时间内保留您的个人信息（除非法律要求或允许我们将信息保留更长的时间）。</p>
			<p>本平台的部分服务由我们的关联方或其他第三方供应商提供，因此出于提供服务所必需，我们可能会将您的个人信息与我们的关联方以及供应商共享（包括境外的关联方以及供应商）并向境外传输您的个人信息，但我们只会共享、传输必要的个人信息，且受本政策中所述目的的约束。</p>
			<p>如果您想进一步了解我们处理您个人信息的具体规则，包括如何根据《个人信息保护法》行使您的权利，请访问 https://www.msdprivacy.com/cn/cn/ 或通过 msdcnprivacy@merck.com联系我们的隐私保护专员。您也可以通过访问<a href="https://www.msdchina.com.cn/termsofuse/">https://www.msdchina.com.cn/termsofuse/</a>了解我们的《使用协议条款》。</p>
		</div>
		<div class="modal">
			<div class="modal-list" v-for="(item, index) in list" :key="index">
				<img @click="changeList(index)" :src="item.state ? imgOn : img" alt="">
				<span>{{item.text}}</span>
			</div>
			<button class="modal-btn" @click="jumpPage">确定</button>
		</div>
	</div>
</template>

<script>
import { Dialog } from 'vant'
import { userConsent } from '../common/api/index'
export default {
  data() {
    return {
			list: [
				{
					text: '全选',
					state: 0
				},
				{
					text: '我已阅读本政策，并同意默沙东按照上述方式处理我的个人信息。',
					state: 0
				},
				{
					text: '我同意默沙东为前述目的将我的个人信息与默沙东的关联方以及供应商共享（包括境外的关联方以及供应商）。',
					state: 0
				},
				{
					text: '我同意默沙东为前述目的将我的个人信息传输出境。',
					state: 0
				}
			],
			num: 0,
			img: require('../assets/img/home/select.png'),
			imgOn: require('../assets/img/home/select-on.png'),
			show: false
		};
  },
  created() {
		// console.log(this.$store.state.consent)
		if (this.$store.state.consent) {
			// this.show = true
			this.list.forEach(function (val) {
				val.state = 1
			})
		}
	},
  mounted() {
		this.$wxShare._wxHideMenu()
	},
  methods: {
		/**
		 * 选择
		 */
		changeList (index) {
			let _state = this.list[index].state
			if (index === 0) {
				this.list.forEach(function (val) {
					val.state = _state ? 0 : 1
				})
			} else {
				this.list[index].state = _state ? 0 : 1
			}
			this.selectAll()
		},

		/**
		 * 判断是否全选
		 */
		selectAll () {
			let select = true
			this.list.forEach(function (val, index) {
				if (index !== 0 && !val.state) {
					select = false
				}
			})
			this.list[0].state = !select ? 0 : 1
		},

		/**
		 * 跳转微邀请
		 */
		jumpPage () {
			let that = this
			if (this.list[0].state) {
				if (this.$store.state.consent != 1) {
					that.userConsent(1)
				} else {
					that.$router.go(-1)
				}
			} else {
				Dialog.confirm({
					title: '',
					message: '未勾选使用协议条款将无法访问任意模块，是否进行勾选操作？',
					confirmButtonText: '继续操作',
					cancelButtonText: '不勾选',
					confirmButtonColor: '#3EC5C0',
				})
					.then(() => {
					})
					.catch(() => {
						if (that.$store.state.consent != 0) {
							that.userConsent(0)
						} else {
							that.$router.go(-1)
						}
					});
			}
		},

		/**
		 * 取消/选择用户协议
		 */
		userConsent (consent) {
			let that = this
			userConsent({openid: this.$store.state.openid, consent: consent}).then(res => {
				if (res.code == 200) {
					that.$store.commit('changeConsent', res.data.consent)
					that.$router.go(-1)
				}
				// console.log(res)
				// document.addEventListener('WeixinJSBridgeReady', function() {
				// 	window.WeixinJSBridge.call('closeWindow') //安卓手机关闭代码
				// }, false)
				// window.WeixinJSBridge.call('closeWindow') //苹果手机关闭代码
			})
		}
	}
};
</script>

<style scoped lang="scss">
h3{
	text-align: center;
	font-size: 34px;
	margin-top: 20px;
}
.block{
	width: 100%;
	height: 50px;
}
.agreement{
	color: #9ABBB9;
	margin: 0 40px;
	font-size: 28px;
	padding-bottom: 640px;

	>div>p{
		margin-top: 50px;
	}
	.modal{
		width: calc(100% - 80px);
		background: #333333;
		position: fixed;
		bottom: 0;
		left: 0;
		padding: 0 40px;
		padding-bottom: 43px;
		.modal-list{
			display: flex;
			align-items: flex-start;
			margin-top: 33px;
			img{
				width: 27px;
				height: 27px;
				margin-top: 5px;
				margin-right: 13px;
				pointer-events: initial;
			}
		}
		button{
			width: 100%;
			height: 80px;
			background: #3EC5C0;
			border-radius: 11px;
			margin-top: 33px;
			font-size: 32px;
			color: #fff;
		}
	}
}
a{
	color: #2D48F8;
}
</style>
